import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import { useAppNotification } from '@/store/app-notification';

// lazy load these modules to avoid unnecessary bundle size
const logEventPromise = import('@/store/analytics').then(({ useAnalytics }) => {
  const { logEvent } = useAnalytics();
  return logEvent;
});
const authPromise = import('@/store/auth').then(async ({ useAuth }) => {
  const { authInitialization, isAdmin } = useAuth();
  await authInitialization;
  return { isAdmin };
});

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/seasons',
    name: 'seasons',
    component: () => import('@/views/SeasonsPage.vue'),
  },
  {
    path: '/seasons/:seasonId',
    name: 'season',
    props: true,
    component: () => import('@/views/SeasonPage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues',
    name: 'leagues',
    props: true,
    component: () => import('@/views/LeaguesPage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues/:leagueId',
    name: 'league',
    props: true,
    component: () => import('@/views/LeaguePage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues/:leagueId/leaderboard',
    name: 'league-leaderboard',
    props: true,
    component: () => import('@/views/SeasonLeaderboardPage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues/:leagueId/leaderboard/:month',
    name: 'month-leaderboard',
    props: true,
    component: () => import('@/views/MonthLeaderboardPage.vue'),
  },
  {
    path: '/seasons/:seasonId/game-weeks',
    name: 'season-game-weeks',
    props: true,
    component: () => import('@/views/SeasonGameWeeksPage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues/:leagueId/game-weeks',
    name: 'league-game-weeks',
    props: true,
    component: () => import('@/views/SeasonGameWeeksPage.vue'),
  },
  {
    path: '/seasons/:seasonId/game-weeks/:gameWeekId',
    name: 'game-week',
    props: true,
    component: () => import('@/views/GameWeekPage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues/:leagueId/game-weeks/:gameWeekId',
    name: 'league-game-week',
    props: true,
    component: () => import('@/views/GameWeekPage.vue'),
  },
  {
    // TODO: SC-135 must be logged in to view if league is private
    path: '/seasons/:seasonId/leagues/:leagueId/game-weeks/:gameWeekId/leaderboard',
    name: 'game-week-leaderboard',
    props: true,
    component: () => import('@/views/GameWeekLeaderboardPage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues/:leagueId/game-weeks/:gameWeekId/h2h/:team1Id/vs/:team2Id',
    name: 'game-week-h2h',
    props: true,
    component: () => import('@/views/Head2HeadPage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues/:leagueId/game-weeks/:gameWeekId/players',
    name: 'league-game-week-players',
    props: true,
    component: () => import('@/views/GameWeekPlayersPage.vue'),
  },
  {
    // same as player page but with leagueId and gameWeekId for breadcrumb navigation
    path: '/seasons/:seasonId/leagues/:leagueId/game-weeks/:gameWeekId/players/:playerId',
    name: 'league-game-week-player',
    props: true,
    component: () => import('@/views/PlayerPage.vue'),
  },
  {
    path: '/seasons/:seasonId/game-weeks/:gameWeekId/players',
    name: 'game-week-players',
    props: true,
    component: () => import('@/views/GameWeekPlayersPage.vue'),
  },
  {
    // same as player page but with gameWeekId for breadcrumb navigation
    path: '/seasons/:seasonId/game-weeks/:gameWeekId/players/:playerId',
    name: 'game-week-player',
    props: true,
    component: () => import('@/views/PlayerPage.vue'),
  },
  {
    path: '/seasons/:seasonId/players/:playerId',
    name: 'player',
    props: true,
    component: () => import('@/views/PlayerPage.vue'),
  },
  {
    // TODO: SC-135 must be logged in to view? (all teams are in global league)
    path: '/seasons/:seasonId/teams/:teamId',
    name: 'team',
    props: true,
    component: () => import('@/views/TeamPage.vue'),
  },
  {
    path: '/seasons/:seasonId/leagues/:leagueId/teams/:teamId',
    name: 'team-stats',
    props: true,
    component: () => import('@/views/TeamStatsPage.vue'),
  },
  {
    // TODO: SC-135 must be logged in to view? (all teams are in global league)
    path: '/seasons/:seasonId/teams/:teamId/rosters/:gameWeekId',
    name: 'team-roster',
    props: true,
    component: () => import('@/views/TeamRosterPage.vue'),
  },
  {
    // TODO: SC-135 must be logged in to view if league is private
    path: '/seasons/:seasonId/leagues/:leagueId/teams/:teamId/rosters/:gameWeekId',
    name: 'league-team-roster',
    props: true,
    component: () => import('@/views/TeamRosterPage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/LogoutPage.vue'),
  },
  {
    path: '/my-teams',
    name: 'my-teams',
    component: () => import('@/views/MyTeamsPage.vue'),
  },
  {
    path: '/rules',
    name: 'rules',
    component: () => import('@/views/RulesPage.vue'),
  },
  {
    path: '/admin/seasons/:seasonId/add-player',
    name: 'admin-season-add-player',
    props: true,
    component: () => import('@/views/admin/AdminAddPlayerPage.vue'),
  },
  {
    path: '/admin/seasons/:seasonId/game-weeks/:gameWeekId',
    name: 'admin-game-week',
    props: true,
    component: () => import('@/views/admin/AdminGameWeekPage.vue'),
  },
  {
    path: '/admin/seasons/:seasonId/game-weeks/:gameWeekId/points',
    name: 'admin-game-week-points',
    props: true,
    component: () => import('@/views/admin/AdminGameWeekPointsPage.vue'),
  },
  {
    path: '/admin/seasons/:seasonId/teams/:teamId/rosters/:gameWeekId',
    name: 'admin-team-roster',
    props: true,
    component: () => import('@/views/admin/AdminTeamRosterPage.vue'),
  },
  {
    path: '/admin/seasons/:seasonId/teams',
    name: 'admin-season-teams',
    props: true,
    component: () => import('@/views/admin/AdminSeasonTeamsPage.vue'),
  },
  {
    path: '/blog/:path(.*)',
    name: 'blog-redirect',
    redirect: (to) => {
      const { path } = to.params;
      window.location.href = `https://blog.nextgenftl.com/${path}${window.location.search}${window.location.hash}`;
      return { name: 'home' };
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const { toastError } = useAppNotification();

// non-admins get redirected to home if they try to access an admin page
router.beforeEach(async (to) => {
  try {
    const { isAdmin } = await authPromise;
    if (to.path.startsWith('/admin') && !isAdmin.value) {
      return {
        name: 'home',
      };
    }
  } catch (error) {
    console.error(error);
    await toastError('An error occurred when trying to initialize auth');
    return {
      name: 'home',
    };
  }
});

router.afterEach(async (to) => {
  const logEvent = await logEventPromise;
  logEvent('screen_view', {
    firebase_screen: to.name,
    params: to.params,
  });
});

export default router;
