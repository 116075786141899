<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <Suspense>
        <AppMenu />
      </Suspense>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineAsyncComponent } from 'vue';

const AppMenu = defineAsyncComponent(() => import('@/components/AppMenu.vue'));

// Auth store is initialized in AppMenu which is already loaded asynchronously
Promise.all([
  import('@/store/analytics').then(({ useAnalytics }) => useAnalytics().init()),
  import('@/store/sw-update').then(({ useSwUpdate }) => useSwUpdate().init()),
  import('@/store/messaging').then(({ useMessaging }) => useMessaging().init()),
]);
</script>
